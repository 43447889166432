import React, { useContext, useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import FullScreenDialog from "./full_dialog";
import { PickingList } from "./card";
import {
  Typography,
  List,
  ListItemButton,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import { Context } from "../../context/app_context";
import { componentServiceConsumer } from "../../common/servicve_consumer";
import { getPickingListSalesOrders } from "../../services/picking_list/picking_list";
import { differenceInMilliseconds, format } from "date-fns";
import Loading from "../loading/loading";
import SalesOrdersDetailDialog from "./sales_order_detail_dialog";
import SearchForm from "../search/form";
import { XCircleIcon } from "@heroicons/react/24/outline/";

export default function SalesOrdersExternalDialog({
  open,
  setOpen,
  currentList,
  showSearch,
}: {
  open: boolean;
  showSearch: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentList?: PickingList;
}) {
  const {
    loading,
    currentTab,
    setLoading,
    itemSearchExternal,
    setItemSearchExternal,
  } = useContext(Context);
  const [salesOrders, setSalesOrders] = useState<any[]>([]);
  const [currentSalesOrder, setCurrentSalesOrder] = useState<any>();
  const [showSalesOrderPopup, setShowSalesOrderPopup] =
    useState<boolean>(false);

  const loadCurrentListSalesOrders = async () => {
    setLoading(true);
    const serviceResponse = await componentServiceConsumer(
      getPickingListSalesOrders,
      currentList?.id,
      "Error Loading Sales Orders: "
    );

    setSalesOrders(serviceResponse.data);
    setLoading(false);
  };

  useEffect(() => {
    if (currentList && open) loadCurrentListSalesOrders();
  }, [currentList]);

  useEffect(() => {
    if (!open) {
      setSalesOrders([]);
      setItemSearchExternal("");
    }
  }, [open]);

  return (
    <>
      <FullScreenDialog
        open={open}
        setOpen={setOpen}
        color={currentList?.color}
        title={currentList?.name}
      >
        <>
          <Loading />

          {showSearch == true ? (
            <>
              <SearchForm currentTab={currentTab} />
              {itemSearchExternal ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      backgroundColor: "#fff3cd",
                    }}
                  >
                    <Typography variant="h6" textAlign={"center"}>
                      Filtering By Item {itemSearchExternal}
                    </Typography>
                    <IconButton onClick={() => setItemSearchExternal("")}>
                      <XCircleIcon style={{ width: "40px", height: "40px" }} />
                    </IconButton>
                  </Box>
                </>
              ) : null}
            </>
          ) : null}
          <List>
            <ListItemButton>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "2fr 3fr 3fr 1fr",
                  padding: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">SO Number - Separator</Typography>
                <Typography variant="body1">Customer PO</Typography>
                <Typography variant="body1">Customer</Typography>
                <Typography variant="body1">Date</Typography>
              </Box>
            </ListItemButton>
            <Divider />
            {loading ? (
              <></>
            ) : (
              salesOrders
                .filter((so) => {
                  if (itemSearchExternal) {
                    return so.SalesOrdersItems.find(
                      (x: any) => x.Item_Name == itemSearchExternal
                    );
                  } else {
                    return true;
                  }
                })
                .sort((a, b) => {
                  const diff = differenceInMilliseconds(
                    new Date(a.Order_Date),
                    new Date(b.Order_Date)
                  );
                  return diff;
                })
                .map((salesOrder, index) => {
                  const {
                    id,
                    SO_Number,
                    nombre_cliente,
                    Customer_PO,
                    Order_Date,
                    separator,
                  } = salesOrder;
                  return (
                    <>
                      <ListItemButton
                        key={`list_item_${SO_Number}_${index}`}
                        onClick={() => {
                          setCurrentSalesOrder({
                            order_number: SO_Number,
                            id_pedido: id,
                            ...salesOrder,
                          });
                          setShowSalesOrderPopup(true);
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns: "2fr 3fr 3fr 1fr",
                            padding: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {separator ? (
                              <>
                                {SO_Number} - {separator?.barcode}
                              </>
                            ) : (
                              <>{SO_Number}</>
                            )}
                          </Typography>
                          <Typography variant="body1">{Customer_PO}</Typography>
                          <Typography variant="body1">
                            {nombre_cliente}
                          </Typography>
                          <Typography variant="body1">
                            {format(new Date(Order_Date), "MM/dd/yyyy HH:mm")}
                          </Typography>
                        </Box>
                      </ListItemButton>
                      <Divider />
                    </>
                  );
                })
            )}
          </List>
        </>
      </FullScreenDialog>
      <SalesOrdersDetailDialog
        open={showSalesOrderPopup}
        setOpen={setShowSalesOrderPopup}
        setUpperOpen={setOpen}
        currentList={currentList}
        currentSalesOrder={currentSalesOrder}
        isExternal={true}
      />
    </>
  );
}
