import React, { useContext, useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import FullScreenDialog from "./full_dialog";
import { PickingList } from "./card";
import { Typography, List, ListItemButton, Divider, Box } from "@mui/material";
import { Context } from "../../context/app_context";
import { componentServiceConsumer } from "../../common/servicve_consumer";
import { getPickingListSalesOrders } from "../../services/picking_list/picking_list";
import { format } from "date-fns";
import Loading from "../loading/loading";
import SalesOrdersDetailDialog from "./sales_order_detail_dialog";

export default function SalesOrdersDialog({
  open,
  setOpen,
  currentList,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentList?: PickingList;
}) {
  const { loading, setLoading } = useContext(Context);
  const [salesOrders, setSalesOrders] = useState<any[]>([]);
  const [currentSalesOrder, setCurrentSalesOrder] = useState<any>();
  const [showSalesOrderPopup, setShowSalesOrderPopup] =
    useState<boolean>(false);

  const loadCurrentListSalesOrders = async () => {
    setLoading(true);
    const serviceResponse = await componentServiceConsumer(
      getPickingListSalesOrders,
      currentList?.id,
      "Error Loading Sales Orders: "
    );

    setSalesOrders(serviceResponse.data);
    setLoading(false);
  };

  useEffect(() => {
    if (currentList && open) loadCurrentListSalesOrders();
  }, [currentList]);
  useEffect(() => {
    if (!open) setSalesOrders([]);
  }, [open]);
  useEffect(() => {
    if (!showSalesOrderPopup && currentList && open) {
      loadCurrentListSalesOrders();
    }
  }, [showSalesOrderPopup]);

  return (
    <>
      <FullScreenDialog
        open={open}
        setOpen={setOpen}
        color={currentList?.color}
        title={currentList?.name}
      >
        <>
          <Loading />
          <List>
            <ListItemButton>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "2fr 3fr 3fr 1fr 2fr",
                  padding: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">SO Number</Typography>
                <Typography variant="body1">Location</Typography>
                <Typography variant="body1">Customer</Typography>
                <Typography variant="body1" textAlign={"center"}>
                  Items
                </Typography>
                <Typography variant="body1">Date</Typography>
              </Box>
            </ListItemButton>
            <Divider />
            {loading ? (
              <></>
            ) : (
              salesOrders.map((salesOrder, index) => {
                const {
                  cantidad_items,
                  fecha_pedido,
                  location,
                  nombre_cliente,
                  order_number,
                } = salesOrder;
                return (
                  <>
                    <ListItemButton
                      key={`list_item_${order_number}_${index}`}
                      onClick={() => {
                        setCurrentSalesOrder(salesOrder);
                        setShowSalesOrderPopup(true);
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "2fr 3fr 3fr 1fr 2fr",
                          padding: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1">{order_number}</Typography>
                        <Typography sx={{ fontSize: "22px", fontWeight: "800" }} variant="body1">{location}</Typography>
                        <Typography variant="body1">
                          {nombre_cliente}
                        </Typography>
                        <Typography variant="h6" textAlign={"center"}>
                          {cantidad_items}
                        </Typography>
                        <Typography variant="body1">
                          {format(new Date(fecha_pedido), "MM/dd/yyyy HH:mm")}
                        </Typography>
                      </Box>
                    </ListItemButton>
                    <Divider />
                  </>
                );
              })
            )}
          </List>
        </>
      </FullScreenDialog>
      <SalesOrdersDetailDialog
        open={showSalesOrderPopup}
        setOpen={setShowSalesOrderPopup}
        setUpperOpen={setOpen}
        currentList={currentList}
        currentSalesOrder={currentSalesOrder}
        isExternal={false}
      />
    </>
  );
}
